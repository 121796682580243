import React from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  DialogTitle,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import * as API from "../API";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import cat_logo from "../images/CATKING-01.png";
import "../style/Login.css";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = (props) => {
  const history = useNavigate();

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      fireLogin();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const fireLogin = () => {
    setLoading(true);
    API.login(email, password)
      .then((res) => {
        setLoading(false);
        let token = res.data.finalUser.token;
        Cookies.set("userInfo", token);
        // {
        //   sameSite: 'none', // Allow cross-site requests
        //   secure: true,     // Require a secure (HTTPS) connection
        // });
        history("/");
      })
      .catch((err) => {
        setLoading(false);
        setError(true);

        console.log(err.response.data.errors);
        setErrorMessage(err.response.data.errors);
        console.log(err.response);
      });
  };
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Dialog
      open={props.open}
      maxWidth="sm"
      fullWidth={true}
      align="center"
      onClose={props.handleClose}
      style={{ zIndex: 3001 }}
    >
      {props.expired && (
        <p style={{ color: "red", fontWeight: "bold" }}>
          Your session has ended. Please log in again
        </p>
      )}

      <DialogTitle>
        <img alt="cat_logo" src={cat_logo} style={{ width: 200 }} />
      </DialogTitle>
      <DialogContent>
        {error && <p style={{ color: "red" }}>{errorMessage}</p>}
        <TextField
          error={error}
          helperText="Email"
          style={{ width: "80%" }}
          onChange={handleEmail}
          variant="outlined"
        />
        <br />
        <TextField
          error={error}
          helperText="Password"
          type={showPassword ? "text" : "password"}
          style={{ width: "80%" }}
          onChange={handlePassword}
          variant="outlined"
          onKeyDown={handleEnter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {" "}
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions align="center" style={{ display: "block" }}>
        {loading && <CircularProgress />}
        <Button
          onClick={fireLogin}
          style={{ textTransform: "none", width: "50%" }}
          variant="contained"
        >
          Log in
        </Button>
        <br />
        <p className="register_button" onClick={props.loadRegister}>
          don't have an account?
        </p>
        <p
          className="register_button"
          onClick={props.loadForgotPW}>
            forgot password?
        </p>
      </DialogActions>
    </Dialog>
  );
};

export default Login;
