import React, { useState } from "react";
import Info from "../../Components/Info";
import "../../style/Home.css";
import "../../style/Typewriter.css";
import default_image from "../../images/cat_default.png";
import { queryCats, getPrice } from "../../API";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Dialog,
  Button,
  Snackbar,
  LinearProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent
} from "@mui/material";
import "../../style/Query.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import { numberWithCommas } from "../../Helpers/functions";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import search_img from "../../images/search_img.png";

// ReactGA.set({user: jwt(Cookies.get('userInfo')).email})

//const photo_store = "https://catking-pics.s3.us-east-2.amazonaws.com";

const photo_store = "https://catking-pics.s3.us-east-2.amazonaws.com";

const Query = (props) => {
  // const gaEventTracker = useAnalyticsEventTracker('search');

  const [userInfo, _] = useOutletContext();
  const [marginValue, setMarginValue] = useState(0);
  const [marginActivated, setMarginActivated] = useState(false);

  const history = useNavigate();

  const snackBarClose = () => {
    setSnackBarOpen(false);
  };

  // only make photo announcement on first use
  React.useEffect(() => {
    if (userInfo) {
      const alreadyLoaded = localStorage["update_announcement_made"];
      if (!alreadyLoaded) {
        setSnackBarOpen(true);
        localStorage.setItem("update_announcement_made", "true");
      }
      setMarginValue(userInfo.margin);
      setMarginActivated(userInfo.margin_activated);
    }

  }, [userInfo]);

  const fireGetPrice = (serial, index) => {
    getPrice(serial)
      .then((res) => {
        let newInfo = [...info];
        newInfo[index].value = Math.trunc(res.data.value);
        setInfo(newInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleSubscribeButton = () => {
    setSubscribeButton(true);
  };

  const fireSearch = (serial) => {
    if (serial.length < 2) {
      return;
    }
    setLoading(true);
    queryCats(serial)
      .then((res) => {
        ReactGA.event({
          category: "user behavior",
          action: "query",
          serial: serial,
        });
        setLoading(false);
        if (res.status === 200) {
          setErrorMessage(null);
          setInfo(res.data);
        } else {
          setErrorMessage("No info available for your query");
        }
      })
      .catch((err) => {
        ReactGA.event({
          category: "user behavior",
          action: "query",
          serial: serial,
        });
        setLoading(false);
        if (err.response.status === 403) {
          setErrorMessage(
            "Subscription required to access cat data: navigate to your account page to set up subscription"
          );
          toggleSubscribeButton();
        } else if (err.response.status === 401) {
          // token has expired. remove cookie and direct to login
          Cookies.remove("userInfo");
          history("/home?expired=true");
        } else if (err.response.status === 429) {
          setErrorMessage("You sent too many requests. Please wait at least 15 mins then try again");
        }
        else {
          setErrorMessage("No info available for your query");
        }
      });
  };

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [info, setInfo] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [catsPerRow, setCatsPerRow] = React.useState('');
  const [subscribeButton, setSubscribeButton] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogImage, setDialogImage] = React.useState(null);

  const changeCatsPerRow = (number) => {
    setCatsPerRow(number);
  };

  const navToAccountSub = () => {
    history("/account?sub=true");
  };

  return (
    <>

      <Snackbar
        open={snackBarOpen}
        onClose={snackBarClose}
        message="We are working hard to add HD images to every cat on our site...stay tuned"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />

      <Dialog
        open={dialogOpen}
        maxWidth="lg"
        fullWidth={false}
        align="center"
        onClose={() => { setDialogOpen(false) }}
      >
        <DialogContent
          style={{ padding: "0px" }}
        >
          <img
            src={dialogImage}
            alt="cat"
            style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
          />
        </DialogContent>
      </Dialog>

      {/* <TypeWriter/> */}
      <Info search={fireSearch} changeRows={changeCatsPerRow} />
      {
        errorMessage === null && info !== null &&
        <FormControl style={{ width: '100%', marginTop: 10 }}>
          <InputLabel id="selector">Items Per Row</InputLabel>
          <Select
            variant="outlined"
            style={{ width: "50%", minWidth: 200, maxWidth: 400, marginLeft: 5 }}
            labelId="selector"
            label="Items Per Row"
            value={catsPerRow}
            onChange={(event) => {
              setCatsPerRow(event.target.value);
            }}
          >
            <MenuItem value={1}>One</MenuItem>
            <MenuItem value={2}>Two</MenuItem>
            <MenuItem value={3}>Three</MenuItem>
            <MenuItem value={4}>Four</MenuItem>
            <MenuItem value={6}>Six</MenuItem>
          </Select>
        </FormControl>
      }
      <Grid
        container
        xs={12}
        sx={{ mt: 5, minHeight: 'calc(100vh - 400px)' }}
      >
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8} align="center">
          {errorMessage && (
            <>
              <p style={{ color: "red" }}>{errorMessage}</p>
              {subscribeButton && (
                <Button onClick={navToAccountSub} variant="outlined">
                  subscribe here
                </Button>
              )}
            </>
          )}
          {
            loading && <LinearProgress color="secondary" />
          }
        </Grid>
        <Grid item xs={1} md={2}
        />
        {errorMessage === null && info === null && (
          <img src={search_img} className="search_image" alt="cat" />
        )}
        {errorMessage === null &&
          info !== null &&
          (
            <>
              {
                info.map((cat, index) => (
                  <Grid
                    item
                    xs={catsPerRow === '' ? 6 : 12 / catsPerRow}
                    md={catsPerRow === '' ? 4 : 12 / catsPerRow}
                    lg={catsPerRow === '' ? 3 : 12 / catsPerRow}
                    style={{ padding: 8 }}
                  >
                    <Card
                      align="center"
                      key={cat["Serial Number"]}
                      // onClick={() => {
                      //   fireGetPrice(cat["Serial Number"], index);
                      // }}
                      className="catCard"
                    >
                      <CardMedia
                        title="cat"
                        component="img"
                        image={
                          cat["image"]
                            ? `${photo_store}/original/${cat["image"]}.JPG`
                            : default_image}
                        sx={{
                          maxHeight: 200,
                          objectFit: "contain",
                          backgroundColor: "rgba(109, 58, 153, .3)",
                          cursor: "pointer"
                        }}
                        onClick={() => { setDialogImage(`${photo_store}/original/${cat["image"]}.JPG`); setDialogOpen(true); }}
                      />
                      <CardContent className="catMargin">
                        <strong style={{ fontWeight: "bold", fontSize: `${cat["Serial Number"].length < 12 ? 20 : 15}px` }}>
                          {cat["Serial Number"]}
                        </strong>
                        <ul className="catTable" align="left">
                          <li style={{ display: "table-row" }}>
                            <div className="itemCell"
                            >
                              Make:{" "}
                            </div>
                            {cat["Make"]}
                          </li>
                          <li style={{ display: "table-row" }}>
                            <div className="itemCell"
                            >
                              Category:{" "}
                            </div>
                            {cat["Category"]}
                          </li>
                          <li style={{ display: "table-row" }}>

                            <div className="itemCell"
                            >
                              Value:
                            </div>
                            <p style={{ display: "inline-block", fontWeight: "bold" }}>
                              {cat["value"] === undefined ? (
                                <strong style={{ color: "purple" }}>$$$</strong>
                              ) : (
                                numberWithCommas(
                                  Math.trunc(
                                    cat["value"] *
                                    (marginActivated
                                      ? marginValue / 100
                                      : 1)
                                  )
                                )
                              )}
                            </p>
                          </li>
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </>)}
      </Grid>
    </>
  );
};

export default Query;
