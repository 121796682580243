import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./Pages/Home";
import Portal from './Pages/Portal';
import Contact from './Pages/Contact';
import Account from './Pages/Portal/Account';
import PortalHome from './Pages/Portal/Home';
import Checkout from "./Pages/Portal/Checkout";
import CancelCheckout from "./Pages/Portal/CancelCheckout";
import SuccessCheckout from "./Pages/Portal/SuccessCheckout";
import Help from "./Pages/Portal/Help";
import Generic from "./Pages/Portal/Generic";
import Orders from "./Pages/Portal/Orders";
import Query from "./Pages/Portal/Query";
import ValidateOtp from "./Pages/ValidateOtp";

const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/validate-otp" element={<ValidateOtp />} />
        <Route path="/" element={<Portal />} >
          <Route exact path="/" element={<PortalHome />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/query" element={<Query />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/cancel" element={<CancelCheckout />} />
          <Route exact path="/success" element={<SuccessCheckout />} />
          <Route exact path="/help" element={<Help />} > </Route>
          <Route exact path="/generic" element={<Generic />} />
        </Route>
        <Route path="*">This page doesn't exist</Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
