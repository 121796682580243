import {
  AppBar,
  IconButton,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@material-ui/icons/Menu";
import "../../style/Nav.css";
import React from "react";
import logo_23 from '../../images/logo_23.png';
import { useNavigate } from 'react-router-dom';

const Nav = (props) => {
  const history = useNavigate();

  return (
    <AppBar className="nav_bar" style={{backgroundColor: "white"}} color="inherit" position="sticky">
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <img alt="cat_logo" src={logo_23} height={40} style={{ cursor: 'pointer' }} onClick={() => { history('/') }} />
        </div>
        <IconButton onClick={props.openNav}>
          <MenuIcon style={{ fontSize: 40, color: "black" }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
