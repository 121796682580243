import './App.css';
import Router from './Router.js';
import ReactGA from 'react-ga4';
import "@fontsource/bayon"

//ReactGA.initialize('G-D5M6D674HV', {debug: true, standardImplementation: true });
const TRACKING_ID = 'G-D5M6D674HV';
ReactGA.initialize(TRACKING_ID, {debug: true});

function App() {
  return (
    <Router />
  );
}

export default App;
