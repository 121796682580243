import React from "react";
import { Table, TableRow, TableCell, Grid, TableHead } from "@material-ui/core";

let holder = [0, 1, 2, 3, 4, 5];

const Orders = () => {


  let headers = ["Date", "CC Model", "Price", "Notes from CatKing"]

  return (
    <>
      <Grid container>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <Table style={{ marginTop: 120}}>
          <TableHead>
              <TableRow style={{ backgroundColor: "#ececec" }} hover={true}>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {headers[0]}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {headers[1]}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {headers[2]}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {headers[3]}
                </TableCell>
              </TableRow>
              {
                  holder.map(item => 
                      <TableRow>
                          <TableCell>
                            12/12/12
                          </TableCell>
                          <TableCell>
                              Torpedo
                          </TableCell>
                          <TableCell>
                              $893
                          </TableCell>
                          <TableCell>
                              Received & in good condition
                          </TableCell>
                          </TableRow>
                  )
              }
            </TableHead>
            {

            }
          </Table>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default Orders;
