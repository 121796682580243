import React from "react";
import { registerAccount } from "../API";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  DialogTitle,
  FormControl,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import cat_logo from "../images/CATKING-01.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import disclaimer from "../images/DISCLAIMER.pdf";
import { Checkbox } from "@mui/material";

const validEmail = new RegExp(
  `^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$`
);
const validPassword = new RegExp(
  "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
);

const Register = (props) => {
  const history = useNavigate();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([false, false]);
  const [buying, setBuying] = React.useState("none");
  const [selling, setSelling] = React.useState("none");
  const [sellUnits, setSellUnits] = React.useState(0);
  const [buyUnits, setBuyUnits] = React.useState(0);
  const [disclaimerChecked, setDisclaimerChecked] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleState = (e) => {
    setState(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleBuyUnits = (e) => {
    setBuyUnits(e.target.value);
  };

  const handleSellUnits = (e) => {
    setSellUnits(e.target.value);
  };

  const handleDisclaimerCheck = () => {
    setDisclaimerChecked(!disclaimerChecked);
  };

  const runChecks = () => {
    let valid = true;
    if (!validEmail.test(email)) {
      console.log("email failed");
      setErrors([true, errors[1]]);
      valid = false;
    }
    if (!validPassword.test(password)) {
      console.log("password failed");
      setErrors([errors[0], true]);
      valid = false;
    }
    return valid;
  };

  const handleBuyingChange = (e) => {
    setBuying(e.target.value);
  };

  const handleSellingChange = (e) => {
    setSelling(e.target.value);
  };

  const fireRegister = () => {
    setLoading(true);
    if (!runChecks()) {
      setLoading(false);
      return;
    }
    registerAccount(
      email,
      password,
      name,
      city,
      state,
      phone,
      buyUnits,
      sellUnits
    )
      .then((res) => {
        setLoading(false);
        let token = res.data.user.token;
        Cookies.set("userInfo", token);
        history("/");
      })
      .catch((err) => {
        setLoading(false);
        setErrorText(err.response.data.errors);
      });
  };

  const dialogClose = () => {
    setErrors([false, false]);
    props.handleClose();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={dialogClose}
        align="center"
        style={{ zIndex: 3001 }}
      >
        <DialogTitle>
          <img src={cat_logo} alt="cat" style={{ width: 200 }} />
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            style={{ width: "80%", marginBottom: 12 }}
            onChange={handleName}
            variant="outlined"
          />
          <TextField
            type="email"
            label="Email"
            style={{ width: "80%", marginBottom: 12 }}
            onChange={handleEmail}
            variant="outlined"
            error={errors[0]}
          />
          <TextField
            label="City"
            style={{ width: "80%", marginBottom: 12 }}
            onChange={handleCity}
            variant="outlined"
          />
          <TextField
            label="State"
            style={{ width: "80%", marginBottom: 12 }}
            onChange={handleState}
            variant="outlined"
          />
          <TextField
            type="phone"
            label="Phone Number"
            style={{ width: "80%", marginBottom: 12 }}
            onChange={handlePhone}
            variant="outlined"
          />
          <TextField
            type="password"
            label="Password"
            style={{ width: "80%", marginBottom: 12 }}
            onChange={handlePassword}
            variant="outlined"
            error={errors[1]}
            helperText="Minimum eight characters, at least one letter, one number and one special character"
          />
          <TextField
            type="password"
            error={confirmPassword !== "" && password !== confirmPassword}
            label="Confirm Password"
            style={{ width: "80%", marginBottom: 12 }}
            onChange={handleConfirmPassword}
            variant="outlined"
            helperText="Passwords must match"
          />
          <FormControl style={{ width: "80%" }}>
            <FormLabel color="primary" align="left">
              Buying?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="yes"
              name="radio-buttons-group"
              value={buying}
              onChange={handleBuyingChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="no"
              />
            </RadioGroup>
            {buying === "yes" && (
              <TextField
                label="Number of units (monthly)"
                onChange={handleBuyUnits}
              />
            )}

            <FormLabel color="primary" align="left" style={{ marginTop: 20 }}>
              Selling?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="yes"
              name="radio-buttons-group"
              value={selling}
              onChange={handleSellingChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="no"
              />
            </RadioGroup>
            {selling === "yes" && (
              <TextField
                label="Number of units (monthly)"
                onChange={handleSellUnits}
              />
            )}
          </FormControl>
          <br />
          <br />
          <br />
          <div style={{ width: "80%" }} align="left">
            <a href={disclaimer} target="_blank" rel="noreferrer">
              disclaimer
            </a>
            <br/>
            <br/>
            <span>
              <Checkbox
                checked={disclaimerChecked}
                onClick={handleDisclaimerCheck}
                style={{ color: "#672f90" }}
              />
              <div style={{verticalAlign: "middle", display: "inline"}}>I acknowledge all facts outlined in disclaimer </div>
            </span>

            <br />
          </div>
          <br />
        </DialogContent>
        {loading && <CircularProgress />}
        {errorText !== '' && <div style={{ color: 'red' }}>{errorText}</div>}
        <DialogActions>
          <Grid container>
            {/* <Grid item xs={4}>
              <a href="/home" align="left" style={{ fontSize: 13 }}>
                back to log in
              </a>
            </Grid> */}
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                align="right"
                disabled={
                  password !== confirmPassword ||
                  name === "" ||
                  email === "" ||
                  password === "" ||
                  disclaimerChecked === false
                }
                onClick={() => {
                  fireRegister();
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Register;
