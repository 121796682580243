import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Slider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CardActionArea,
  Tab,
  Tabs,
  Box,
  Divider,
  TextField,
  CircularProgress
} from "@mui/material";
import { createPortalSession, fireCheckout, changePassword } from "../../API";
import { loadStripe } from "@stripe/stripe-js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useOutletContext } from "react-router-dom"
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useLocation } from "react-router-dom";
import price from "./stripe/config";
import "../../style/Account.css";
import config from '../../config/master.js'

const pk = config.isLive
  ? "pk_live_51KfT1nCNqZxm8RYJ1649QrV742EeXo43JRAhDJO8mGHkbC0wFkQ4LTjHbIVeWjVYdGHrWbfmyGOKf7S3N8irIykT00WwVAtnV9"
  : "pk_test_51KfT1nCNqZxm8RYJSI1kcMIrdc8hA3Zl6xr6YJQRqTBhLdHUkpWmqeRa44Q1hzuJMNLnepZHwBdpMkrB9ES3sN4S003sFfwhJ0";

const checkout = (selected) => {
  fireCheckout(parseSelection(selected))
    .then(async function (session) {
      const stripePromise = loadStripe(pk);
      const stripe = await stripePromise;
      return stripe.redirectToCheckout({ sessionId: session.data.sessionId });
    })
    .then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, you should display the localized error message to your
      // customer using `error.message`.
      if (result.error) {
        alert(result.error.message);
      }
    })
    .catch((err) => {
      console.error("error with stripe processing ", err);
    });
};

const parseSelection = (selection) => {
  if (selection === "BASIC MONTHLY") {
    return price.basic_monthly;
  } else if (selection === "PREMIUM MONTHLY") {
    return price.premium_monthly;
  } else if (selection === "BASIC ANNUALLY") {
    return price.basic_annually;
  } else if (selection === "TRIAL") {
    return price.trial;
  } else {
    return price.premium_annually;
  }
};

const directToPortal = () => {
  createPortalSession()
    .then((url) => {
      window.location.href = url.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const listItemIncluded = (text) => (
  <ListItem>
    <ListItemIcon>
      <CheckBoxIcon style={{ color: "#672f90" }} />
    </ListItemIcon>
    <ListItemText>
      <div style={{ fontSize: "calc(12px + .18vw)", lineHeight: 1.1 }}>
        {text}
      </div>
    </ListItemText>
  </ListItem>
);

const subscriptionTiers = (info) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <Typography
              align="left"
              variant="h5"
              style={{ marginLeft: 32, fontFamily: "Bayon", marginTop: 10 }}
            >
              Trial
            </Typography>
            <hr />
            <CardContent align="center">
              <List dense={true} disabledPadding={true}>
                {listItemIncluded("Unlimited Searches")}
                {listItemIncluded("Daily Prices for Generics")}
                {listItemIncluded("Real-time Metal Prices")}
                {listItemIncluded("Adjustable Margins")}
              </List>
            </CardContent>
            <CardActionArea align="center" style={{ padding: "none" }}>
              {info.account === "TRIAL" ? (
                <Button
                  style={{
                    width: "100%",
                    color: "#672f90",
                    textTransform: "none",
                  }}
                  onClick={directToPortal}
                >
                  Manage Subscription
                </Button>
              ) : (
                <Button
                  style={{
                    width: "100%",
                    color: "#672f90",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    checkout("TRIAL");
                  }}
                >
                  Subscribe
                </Button>
              )}
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Typography
              align="left"
              variant="h5"
              style={{ marginLeft: 32, fontFamily: "Bayon", marginTop: 10 }}
            >
              Gold
            </Typography>
            <Typography
              align="left"
              variant="body"
              gutterBottom
              style={{
                marginLeft: 32,
                fontFamily: "Bayon",
                color: "grey",
                marginTop: 10,
              }}
            >
              $9.99/month
            </Typography>{" "}
            <hr />
            <CardContent>
              <List dense={true} disabledPadding={true}>
                {listItemIncluded("Unlimited Searches")}
                {listItemIncluded("Daily Prices for Generics")}
                {listItemIncluded("Real-time Metal Prices")}
                {listItemIncluded("Adjustable Margins")}
              </List>
            </CardContent>
            <CardActionArea align="center" style={{ padding: "none" }}>
              {info.account === "BASIC MONTHLY" ? (
                <Button
                  style={{
                    width: "100%",
                    color: "#672f90",
                    textTransform: "none",
                  }}
                  onClick={directToPortal}
                >
                  Manage Subscription
                </Button>
              ) : (
                <Button
                  style={{
                    width: "100%",
                    color: "#672f90",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    checkout("BASIC MONTHLY");
                  }}
                >
                  Subscribe
                </Button>
              )}
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Typography
              align="left"
              variant="h5"
              style={{ marginLeft: 32, fontFamily: "Bayon", marginTop: 10 }}
            >
              Platinum
            </Typography>
            <Typography
              align="left"
              variant="body"
              gutterBottom
              style={{
                marginLeft: 32,
                fontFamily: "Bayon",
                color: "grey",
                marginTop: 10,
              }}
            >
              $34.99/month
            </Typography>
            <hr />
            <CardContent align="center">
              <List dense={true} disabledPadding={true}>
                {listItemIncluded("Unlimited Searches")}
                {listItemIncluded("Daily Prices for Generics")}
                {listItemIncluded("Real-time Metal Prices")}
                {listItemIncluded("Adjustable Margins")}
                {listItemIncluded("24/7 Phone Support")}
              </List>
            </CardContent>
            <CardActionArea align="center" style={{ padding: "none" }}>
              {info.account === "PREMIUM MONTHLY" ? (
                <Button
                  style={{ width: "100%", textTransform: "none" }}
                  onClick={directToPortal}
                >
                  Manage Subscription
                </Button>
              ) : (
                <Button
                  disabled
                    style={{ width: "100%", textTransform: "none", }}
                  onClick={() => {
                    checkout("PREMIUM MONTHLY");
                  }}
                >
                  Subscribe
                </Button>
              )}
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const yearlySubscriptionTiers = (info) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={0} md={2} />
        <Grid item xs={12} md={5}>
          <Card>
            <Typography
              align="left"
              variant="h5"
              style={{ marginLeft: 32, fontFamily: "Bayon", marginTop: 10 }}
            >
              Gold
            </Typography>
            <Typography
              align="left"
              variant="body"
              gutterBottom
              style={{
                marginLeft: 32,
                fontFamily: "Bayon",
                color: "grey",
                marginTop: 10,
              }}
            >
              $99.99/yr
            </Typography>{" "}
            <hr />
            <CardContent>
              <List dense={true} disabledPadding={true}>
                {listItemIncluded("Unlimited Searches")}
                {listItemIncluded("Daily Prices for Generics")}
                {listItemIncluded("Real-time Metal Prices")}
                {listItemIncluded("Adjustable Margins")}
              </List>
            </CardContent>
            <CardActionArea align="center" style={{ padding: "none" }}>
              {info.account === "BASIC ANNUALLY" ? (
                <Button
                  style={{ width: "100%", textTransform: "none" }}
                  onClick={directToPortal}
                >
                  Manage Subscription
                </Button>
              ) : (
                <Button
                  disabled
                  style={{
                    width: "100%",
                    color: "#672f90",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    checkout("BASIC ANNUALLY");
                  }}
                >
                  Subscribe
                </Button>
              )}
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card>
            <Typography
              align="left"
              variant="h5"
              style={{ marginLeft: 32, fontFamily: "Bayon", marginTop: 10 }}
            >
              Platinum
            </Typography>
            <Typography
              align="left"
              variant="body"
              gutterBottom
              style={{
                marginLeft: 32,
                fontFamily: "Bayon",
                color: "grey",
                marginTop: 10,
              }}
            >
              $299.99/yr
            </Typography>
            <hr />
            <CardContent align="center">
              <List dense={true} disabledPadding={true}>
                {listItemIncluded("Unlimited Searches")}
                {listItemIncluded("Daily Prices for Generics")}
                {listItemIncluded("Real-time Metal Prices")}
                {listItemIncluded("Adjustable Margins")}
                {listItemIncluded("24/7 Phone Support")}
              </List>
            </CardContent>
            {info.account === "PREMIUM ANNUALLY" ? (
              <Button
                style={{
                  width: "100%",
                  color: "#672f90",
                  textTransform: "none",
                }}
                onClick={directToPortal}
              >
                Manage Subscription
              </Button>
            ) : (
              <Button
                disabled
                style={{ width: "100%", textTransform: "none" }}
                onClick={() => {
                  checkout("PREMIUM ANNUALLY");
                }}
              >
                Subscribe
              </Button>
            )}
          </Card>
        </Grid>
        <Grid item xs={0} md={2} />
      </Grid>
    </>
  );
};

const useQueryParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Account = () => {
  const query = useQueryParams();
  const [userInfo, saveMargin] = useOutletContext();

  const [marginActivated, setMarginActivated] = useState(false);
  const [subOpen, setSubOpen] = useState(
    query.get("sub") === "true" ? true : false
  );
  const [generalOpen, setGeneralOpen] = useState(query.get("change-pw") === "true" ? true : false);
  const [newPw, setNewPw] = useState('');
  const [confirmNewPw, setConfirmNewPw] = useState('');
  const [sliderValue, setSliderValue] = useState(0);
  const [tab, setTab] = useState(0);
  const [changePwError, setChangePwError] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (userInfo) {
      setSliderValue(userInfo.margin);
      setMarginActivated(userInfo.margin_activated);
    }
  }, [userInfo]);

  const fireChangePw = async () => {
    setLoading(true);

    if (newPw !== confirmNewPw) {
      setChangePwError(true);
      setLoading(false);
      return;
    }
    changePassword(newPw)
      .then(res => {
        setLoading(false);
        alert("success");
      })
      .catch(err => {
        setLoading(false);
        alert(err);
      })
  }

  if (!userInfo) {
    return <div>loading...</div>
  }

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleMarginToggle = () => {
    setMarginActivated(!marginActivated);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = () => {
    setSubOpen(!subOpen);
  };

  const handleGeneralChange = () => {
    setGeneralOpen(!generalOpen);
  }

  const handleNewPw = (event) => {
    setNewPw(event.target.value);
  };

  const handleConfirmNewPw = (event) => {
    setConfirmNewPw(event.target.value);
  };

  if (userInfo) {
  return (
    <>
      <Grid
        container
        sx={{ minHeight: "calc(100vh - 276px)", marginTop: "40px" }}
      >
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Accordion style={{ marginTop: 15, boxShadow: "none" }} expanded={generalOpen} onChange={handleGeneralChange}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1b-header"
              style={{ paddingLeft: 5, fontFamily: "Bayon" }}
            >
              General settings
            </AccordionSummary>
            <AccordionDetails>
              {userInfo && (
                <>
                  <Typography align="left">{userInfo.name}</Typography>
                  <Typography align="left">{userInfo.email}</Typography>
                  <Typography align="left">{userInfo.phone}</Typography>
                  <Typography align="left">
                    {userInfo.city}, {userInfo.state}
                  </Typography>
                  <h3 style={{ fontFamily: "Bayon" }}>
                    change password
                  </h3>
                  <TextField label="new password" value={newPw} error={changePwError} onChange={handleNewPw} type="password" />
                  <TextField label="confirm password" value={confirmNewPw} error={changePwError} onChange={handleConfirmNewPw} type="password" />
                  {
                    loading &&
                    <CircularProgress />
                  }
                  <br />
                  <Button
                    onClick={fireChangePw}
                    style={{
                      marginTop: 10,
                      borderColor: "#632c93",
                      textTransform: "none",
                      color: "#632c93",
                      fontFamily: "Bayon"
                    }}
                    variant="outlined"
                    disabled={loading}
                  >
                    Change
                  </Button>
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ paddingLeft: 5, fontFamily: "Bayon" }}
            >
              Margin Settings
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={marginActivated}
                      onClick={handleMarginToggle}
                      style={{ color: "#672f90" }}
                    />
                  }
                  label="Enable Margin"
                />
              </FormGroup>
              {sliderValue !== undefined && (
                <Slider
                  style={{ marginTop: 30, color: "#672f90" }}
                  value={sliderValue}
                  step={1}
                  valueLabelDisplay="on"
                  onChange={handleSliderChange}
                />
              )}

              <Button
                onClick={() => {
                  saveMargin(sliderValue, marginActivated);
                }}
                style={
                  sliderValue !== userInfo.margin ||
                    marginActivated !== userInfo.margin_activated
                    ? { color: "#672f90" }
                    : {}
                }
                disabled={
                  !(
                    sliderValue !== userInfo.margin ||
                    marginActivated !== userInfo.margin_activated
                  )
                }
              >
                Save
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={subOpen}
            onChange={handleChange}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ paddingLeft: 5, fontFamily: "Bayon" }}
            >
              Subscription Settings
            </AccordionSummary>
            <AccordionDetails>
              {/* <div style={{ fontFamily: "Bayon", textAlign: "left", padding: "20px" }}>
                <p>
                  As a promo, we are currently offering our base package for free!
                </p>
                <p>
                  If you have an account pre-dating this promotion and would like to make a change, please text
                  <a href="tel:+17207455177" style={{ textDecoration: "none", color: "#007bff" }}> (720)-745-5177 </a>
                  for assistance.
                </p>
              </div> */}

              {userInfo && (
                <>
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                  >
                    <Tab label="Monthly" style={{ textTransform: "none" }} />
                    <Tab
                      label="Yearly (3 free months!)"
                      style={{ textTransform: "none" }}
                    />
                  </Tabs>

                  <TabPanel value={tab} index={0}>
                    {subscriptionTiers(userInfo)}
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    {yearlySubscriptionTiers(userInfo)}
                  </TabPanel>
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
    )
  }
};

export default Account;
