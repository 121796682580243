import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Dialog,
  DialogContent,
  Card,
  CardMedia,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
import { genericData } from "../../API";
import { numberWithCommas } from "../../Helpers/functions";
import default_image from "../../images/cat_default.png";

function createData(category, type, price) {
  return { category, type, price };
}

const photo_store = "https://catking-pics.s3.us-east-2.amazonaws.com";

const rows = [
  createData("Honeycomb", "Large GM", 174),
  createData("Honeycomb", "Medium GM", 174),
  createData("Honeycomb", "GM Airpipe", 174),
  createData("Honeycomb", "GM Flow Special", 174),
  createData("Honeycomb", "X Body", 174),
];

const Generic = (props) => {
  React.useEffect(() => {
    genericData()
      .then((res) => {
        setGenerics(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [catsPerRow, setCatsPerRow] = useState('');
  const [generics, setGenerics] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState(null);

  return (
    <>
      <Dialog
        open={dialogOpen}
        maxWidth="lg"
        fullWidth={false}
        align="center"
        onClose={() => { setDialogOpen(false) }}
      >
        <DialogContent
          style={{ padding: "0px" }}
        >
          <img
            src={dialogImage}
            alt="cat"
            style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
          />
        </DialogContent>
      </Dialog>
    <Box sx={{ flexGrow: 1 }}>
        <FormControl style={{ width: '100%', marginTop: 10 }}>
          <InputLabel id="selector">Items Per Row</InputLabel>
          <Select
            variant="outlined"
            style={{ width: "50%", minWidth: 200, maxWidth: 400, marginLeft: 5 }}
            labelId="selector"
            label="Items Per Row"
            value={catsPerRow}
            onChange={(event) => {
              setCatsPerRow(event.target.value);
            }}
          >
            <MenuItem value={1}>One</MenuItem>
            <MenuItem value={2}>Two</MenuItem>
            <MenuItem value={3}>Three</MenuItem>
            <MenuItem value={4}>Four</MenuItem>
            <MenuItem value={6}>Six</MenuItem>
          </Select>
      </FormControl>
      <Grid container xs={12} style={{ margin: 0, marginTop: 30 }} spacing={2}>
        {/* <Grid item xs={1} md={2} />
            <Grid item xs={1} md={2} /> */}
        {generics &&
          generics.map((cat, index) => (
            <Grid
              item
              xs={catsPerRow === '' ? 6 : 12 / catsPerRow}
              md={catsPerRow === '' ? 4 : 12 / catsPerRow}
              lg={catsPerRow === '' ? 3 : 12 / catsPerRow}
            >
              <Card
                align="center"
                key={cat["Serial Number"]}
                className="catCard"
                style={{ height: '100%' }}
              >
                <CardMedia
                  title="cat"
                  component="img"
                  image={
                    cat["image"]
                      ? `${photo_store}/original/${cat["image"]}.jpeg`
                      : default_image
                  }
                  sx={{ maxHeight: 200, objectFit: "contain", backgroundColor: cat["image"] ? 'transparent' : 'rgba(109, 58, 153, .3)', cursor: "pointer" }}
                  onClick={() => { setDialogImage(`${photo_store}/original/${cat["image"]}.jpeg`); setDialogOpen(true); }}
                />
                <CardContent className="catMargin">
                  <strong style={{ fontWeight: "bold" }}>{cat["name"]}</strong>
                  <br />
                  {
                    <div style={{ display: "inline" }} >
                      <strong style={{ color: "purple" }}>Value: $$$</strong>
                      {/* Value: {" "}
                      {cat["value"] === undefined ? (
                        <strong style={{ color: "purple" }}>$$$</strong>
                      ) : <div style={{ fontWeight: "bold", display: "inline" }}>{(
                        numberWithCommas(
                          Math.trunc(
                            cat["value"] *
                            (props.marginActivated
                              ? props.marginValue / 100
                              : 1)
                          )
                        )
                      )}</div>} */}
                    </div>
                  }
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
    </>
  );
};

export default Generic;
