import React from "react";
import Nav from "../Components/Nav";
import "../style/Home.css";
import "../style/Typewriter.css";
import { Grid, Card, CardContent } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import Footer from "../Components/Footer";
import {useLocation} from 'react-router-dom';

const photo_store = "https://catking-pics.s3.us-east-2.amazonaws.com";

const cats = [
  { name: "RENTAL TRUCK", value: "$$$", image: "original/rental_truck.jpeg" },
  { name: "XL24", value: "$$$", image: "original/xl24.jpeg" },
  { name: "SUBARU KIDNEY", value: "$$$", image: "original/subaru_kidney.jpeg" },
  { name: "OVAL", value: "$$$", image: "original/oval.jpeg" },
  { name: "CNV01", value: "$$$", image: "original/cnv01.JPG" },
  { name: "K2BW", value: "$$$", image: "original/k2bw.jpeg" },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Home = () => {
  const [isMobile, setIsMobile] = React.useState(null);

  const location = useLocation();

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 600);
  }, []);

  window.addEventListener("resize", (event) => {
    setIsMobile(window.innerWidth < 600);
  });

  return (
    <>
      <div className="homebg">
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          className="featureGrid"
        >
          <Grid item xs={isMobile ? 1 : 2} />
          <Grid item xs={isMobile ? 10 : 8} className="homeText">
            <div
              align="center"
              style={{
                color: "white",
                fontWeight: "bold",
                fontFamily: "Bayon",
                lineHeight: 1.15,
                marginBottom: isMobile ? 60 : "20vh",
                paddingTop: isMobile ? 80 : 150,
                fontSize: isMobile ? 30 : 50,
              }}
            >
              Your first stop for catalytic converter pricing{" "}
            </div>
          </Grid>
          <Grid item xs={isMobile ? 1 : 2} />
          <Grid container rowSpacing={2} columnSpacing={isMobile ? 0 : 4} style={{justifyContent: "center"}}>
            <Grid item xs={10} sm={6} lg={3}>
              <Card style={{ height: "100%" }}>
                <CardContent align="center" style={{ display: "flex" }}>
                  <AutoAwesomeIcon
                    style={{
                      fontSize: 40,
                      color: "#632c93",
                      borderRadius: 40,
                      padding: 12,
                      backgroundColor: "#F8EFFF",
                    }}
                  />
                  <div className="catcard">15,000+ unique catalytic converters</div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={10} sm={6} lg={3}>
              <Card style={{ height: "100%" }}>
                <CardContent align="center" style={{ display: "flex" }}>
                  <ImageIcon
                    style={{
                      fontSize: 40,
                      color: "#632c93",
                      borderRadius: 40,
                      padding: 12,
                      backgroundColor: "#F8EFFF",
                    }}
                  />
                  <div className="catcard">15,000+ 4K photos</div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={10} sm={6} lg={3}>
              <Card style={{ height: "100%" }}>
                <CardContent align="center" style={{ display: "flex" }}>
                  <InfoIcon
                    style={{
                      fontSize: 40,
                      color: "#632c93",
                      borderRadius: 40,
                      padding: 12,
                      backgroundColor: "#F8EFFF",
                    }}
                  />
                  <div className="catcard">125 generics updated daily</div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={10} sm={6} lg={3}>
              <Card style={{ height: "100%" }}>
                <CardContent align="center" style={{ display: "flex" }}>
                  <FollowTheSignsIcon
                    style={{
                      fontSize: 40,
                      color: "#632c93",
                      borderRadius: 40,
                      padding: 12,
                      backgroundColor: "#F8EFFF",
                    }}
                  />
                  <div className="catcard">Sign up in just a few minutes</div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div align="center" style={{ paddingTop: 40, paddingBottom: 80 }}>
        <div style={{ fontFamily: "Bayon", fontSize: 30, padding: 40 }}>
          Most popular converter searches
        </div>
        <Grid container style={{ marginTop: 30 }} justifyContent="center">
          <Grid item xs={10}>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              arrows={false}
              renderDotsOutside={false}
              showDots={true}
              swipeable={true}
              draggable={false}
              itemClass="image_item"
              partialVisbile={false}
            >
              {cats.map((cat) => (
                <div
                  align="center"
                  key={cat["Serial Number"]}
                  style={{ border: "none" }}
                >
                  <img
                    title="cat"
                    src={`${photo_store}/${cat["image"]}`}
                    style={{
                      maxHeight: 200,
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="cat"
                  />
                  <div className="catMargin">
                    <strong style={{ fontSize: 18 }}>{cat["name"]}</strong>
                    {
                      <div style={{ marginTop: 10, fontSize: 16 }}>
                        <div style={{ color: "grey", display: "inline" }}>
                          Value:{" "}
                        </div>
                        <strong style={{ color: "black" }}>$$$</strong>
                      </div>
                    }
                  </div>
                </div>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </div>
      <Nav open={location.search === '?expired=true'}/>
      <Footer isMobile={isMobile}/>
    </>
  );
};

export default Home;
