import Cookies from 'js-cookie';
import config from './config/master';
const axios = require('axios');

const local = config.isLocal;

const ip = "localhost";
const port = 1209;

let base = `http://${ip}:${port}`;

if (!local) {
    base = `https://catking-backend.herokuapp.com`
}

const login = (email, password) => {

    return axios({
        method: 'POST',
        url: `${base}/users/login/`,
        data: {
            email: email,
            password: password
        },
        headers: {
            'Content-type': 'application/json'
        }
    });
}

const queryCats = (serial) => {
    const encoded = encodeURIComponent(serial);
    return axios({
        method: 'GET',
        url: `${base}/cats/querycats/${encoded}`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getPrice = (serial) => {
    const encoded = encodeURIComponent(serial);
    return axios({
        method: 'GET',
        url: `${base}/cats/getprice/${encoded}`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const fireCheckout = (priceId) => {
    return axios({
        method: "POST",
        url: `${base}/payment/create-checkout-session`,
        headers: {
            "Content-Type": "application/json",
            'authorization': `Token ${Cookies.get('userInfo')}`
        },
        data: {
            priceId: priceId
        }
    })
}

const getAccountInfo = () => {
    return axios({
        method: "GET",
        url: `${base}/users/accountinfo`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const registerAccount = (email, password, name, city, state, phone, buyUnits, sellUnits) => {
    return axios({
        method: "POST",
        url: `${base}/users/register`,
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            email: email,
            password: password,
            name: name,
            city: city,
            state: state,
            phone: phone,
            buying_amount: buyUnits,
            selling_amount: sellUnits
        }
    })
}

const getAccountStatus = () => {
    return axios({
        method: "GET",
        url: `${base}/users/accounttype`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const createPortalSession = () => {
    return axios({
        method: "POST",
        url: `${base}/payment/create-portal-session`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getMetalPrices = () => {
    return axios({
        method: "GET",
        url: `${base}/cats/getmetalprices`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const setMargin = (marginValue, marginActivated) => {
    return axios({
        method: "POST",
        url: `${base}/users/setmargin`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        },
        data: {
            marginValue: marginValue,
            marginActivated: marginActivated
        }
    })
}

const genericData = () => {
    return axios({
        method: "GET",
        url: `${base}/cats/getgenericdata`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const resetPassword = (email) => {
    return axios({
        method: "POST",
        url: `${base}/users/resetpassword`,
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            email: email
        }
    })
}

const refreshJWT = () => {
    return axios({
        method: "POST",
        url: `${base}/users/refreshjwt`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const validateOtp = (email, otp) => {
    return axios({
        method: "POST",
        url: `${base}/users/validate-otp`,
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            email: email,
            otp: otp
        }
    })
}

const changePassword = (password) => {
    return axios({
        method: "POST",
        url: `${base}/users/change-pw`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        },
        data: {
            password: password,
        }
    })
}

export { login, queryCats, getPrice, fireCheckout, getAccountInfo, registerAccount, getAccountStatus, createPortalSession, getMetalPrices, setMargin, genericData, resetPassword, refreshJWT, validateOtp, changePassword };
