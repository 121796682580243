import React from "react";
import {
  TextField,
  Button,
  Grid,
  IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../style/Info.css";
import { getMetalPrices } from "../API";
import Settings from "@mui/icons-material/Settings";
import { numberWithCommas } from "../Helpers/functions";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchOutlined from "@mui/icons-material/SearchOutlined";

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderBottomColor: "red",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "75%",
  },
}));

const Info = (props) => {
  // const [make, setMake] = React.useState("");
  // const [model, setModel] = React.useState("");
  const [serial, setSerial] = React.useState("");
  // const [country, setCountry] = React.useState("");
  // const [navPosition, setNavPosition] = React.useState(1);
  const [metals, setMetals] = React.useState({
    platinum: 1000,
    palladium: 2000,
    rhodium: 18500,
  });

  const metalArrowChange = (direction) => {
    return direction === "up" ? (
      <ArrowDropUpIcon style={{ color: "green", verticalAlign: "middle" }} />
    ) : (
      <ArrowDropDownIcon style={{ color: "red", verticalAlign: "middle" }} />
    );
  };

  const buttons = [
    <Button
      style={{ color: "#672f90", borderColor: "#672f90" }}
      onClick={() => {
        props.changeRows(1);
      }}
    >
      One
    </Button>,
    <Button
      style={{ color: "#672f90", borderColor: "#672f90" }}
      onClick={() => {
        props.changeRows(2);
      }}
    >
      Two
    </Button>,
    <Button
      style={{ color: "#672f90", borderColor: "#672f90" }}
      onClick={() => {
        props.changeRows(3);
      }}
    >
      Three
    </Button>,
    <Button
      style={{ color: "#672f90", borderColor: "#672f90" }}
      onClick={() => {
        props.changeRows(4);
      }}
    >
      Four
    </Button>,
    <Button
      style={{ color: "#672f90", borderColor: "#672f90" }}
      onClick={() => {
        props.changeRows(6);
      }}
    >
      Six
    </Button>,
  ];

  const handleSettingsClick = (event) => {
    props.changeRows(event.target.value);
  };

  React.useEffect(() => {
    getMetalPrices()
      .then((res) => {
        setMetals(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const handleMake = (event) => {
  //   setMake(event.target.value);
  // };

  // const handleModel = (event) => {
  //   setModel(event.target.value);
  // };

  const handleSerial = (event) => {
    setSerial(event.target.value.toString().toUpperCase().replaceAll(" ", ""));
  };

  // const handleTabChange = (event, newValue) => {
  //   setNavPosition(newValue);
  // };

  const fireSearch = () => {
    props.search(serial);
  };

  const waitForEnter = (event) => {
    if (event.key === "Enter") {
      fireSearch();
      event.target.blur();
    }
  };

  return (
    <div className="info_input">
      <Grid container>
        <Grid
          xs={12}
          container
          justifyContent="center"
          style={{ width: "74%", fontFamily: "Bayon" }}
          columnSpacing={2}
          rowSpacing={2}
        >
          <Grid item xs={8} sm={3} lg={2} align="center">
            <div
              style={{
                display: "table",
                justifyContent: "space-between",
                paddingLeft: 4,
                borderRadius: 5,
                backgroundColor: "white",
                width: "100%"
              }}
            >
              <div style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 12, verticalAlign: "middle", display: "table-cell", fontFamily: "arial" }}> Platinum (Pt): </div>
              {numberWithCommas(metals.platinum)}
              {metalArrowChange(metals.platinum_fluctuation)}
            </div>
          </Grid>
          <Grid item xs={8} sm={3} lg={2} align="center">
            <div
              style={{
                display: "table",
                justifyContent: "space-between",
                paddingLeft: 4,
                borderRadius: 5,
                backgroundColor: "white",
                width: "100%"
              }}
            >
              <div style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 12, verticalAlign: "middle", display: "table-cell", fontFamily: "arial" }}> Palladium (Pd): </div>
              {numberWithCommas(metals.palladium)}
              {metalArrowChange(metals.palladium_fluctuation)}{" "}
            </div>
          </Grid>
          <Grid item xs={8} sm={3} lg={2} align="center">
            <div
              style={{
                display: "table",
                justifyContent: "space-between",
                paddingLeft: 4,
                borderRadius: 5,
                backgroundColor: "white",
                width: "100%"
              }}
            >
              <div style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: 12, verticalAlign: "middle", display: "table-cell", fontFamily: "arial" }}> Rhodium (Rh): </div>
              {numberWithCommas(metals.rhodium)}
              {metalArrowChange(metals.rhodium_fluctuation)}{" "}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3} />
        <Grid item xs={12} md={6} style={{ marginTop: 40 }} align="center">
          <IconButton
            onClick={handleSettingsClick}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <Settings />
          </IconButton>
          <br />
          <form action=".">
            <StyledTextField
              helperText={serial.length < 2 ? "enter 2+ characters" : ""}
              variant="filled"
              onChange={handleSerial}
              onKeyDown={waitForEnter}
              type="search"
              label="Enter Serial Number Here"
              // color="#632c93"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={fireSearch}>
                    <SearchOutlined color="#632c93" />
                  </IconButton>
                ),
              }}
            />
          </form>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
    </div>
  );
};

export default Info;
