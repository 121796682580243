import React from "react";
import { fireCheckout, getAccountStatus } from "../../API";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Card, CardMedia, CardContent, Grid } from "@material-ui/core";
import EcoIcon from "@material-ui/icons/Eco";
import StarsIcon from "@material-ui/icons/Stars";
import config from '../../config/master.js'

const price_id = "price_1KnpqbCNqZxm8RYJBNUsFZVQ";
const premium_plan_id = "price_1KnppoCNqZxm8RYJ8IEfrrr9";

const pk = config.isLive 
  ? "pk_live_51KfT1nCNqZxm8RYJ1649QrV742EeXo43JRAhDJO8mGHkbC0wFkQ4LTjHbIVeWjVYdGHrWbfmyGOKf7S3N8irIykT00WwVAtnV9"
  : "pk_test_51KfT1nCNqZxm8RYJSI1kcMIrdc8hA3Zl6xr6YJQRqTBhLdHUkpWmqeRa44Q1hzuJMNLnepZHwBdpMkrB9ES3sN4S003sFfwhJ0";

const checkout = (selected) => {
  fireCheckout(selected)
    .then(async function (session) {
      const stripePromise = loadStripe(pk);
      const stripe = await stripePromise;
      return stripe.redirectToCheckout({ sessionId: session.data.sessionId });
    })
    .then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, you should display the localized error message to your
      // customer using `error.message`.
      if (result.error) {
        alert(result.error.message);
      }
    })
    .catch((err) => {
      console.log("error with stripe processing ", err);
    });
};

const Checkout = (props) => {
  const [selected, setSelected] = React.useState(null);
  const [accountType, setAccountType] = React.useState('');
  const [queries, setQueries] = React.useState(0);

  React.useEffect(() => {
    getAccountStatus()
    .then(res => {
      setAccountType(res.data.account);
      setQueries(res.data.queries_remaining);
    })
    .catch(err => {
      console.log(err);
    })
  }, [])

  const params = new URLSearchParams(props.location.search);
  const session_id = params.get("session_id");
  // set session for stripe
  localStorage.setItem("stripe_session", session_id);

  return (
    <>
      <div style={{ marginTop: 120 }} align="center">
        <p>You are currently enrolled in the {accountType} plan. You have {queries} remaining queries today</p>
        <Grid container>
          <Grid item xs={0} md={1} />
          <Grid item xs={12} md={5}>
            <Card style={{border: selected === price_id ? '2px solid purple' : 'none', cursor: 'pointer'}} onClick={() => {setSelected(price_id)}}>
              <CardMedia align="center">
                <EcoIcon />
              </CardMedia>
              <CardContent>
                5 requests per day
                <br />
                <strong> $35.00 per month</strong>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card style={{border: selected === premium_plan_id ? '2px solid purple' : 'none', cursor: 'pointer'}} onClick={() => {setSelected(premium_plan_id)}}>
              <CardMedia align="center">
                <StarsIcon />
              </CardMedia>
              <CardContent>
                unlimited requests
                <br />
                <strong> $75.00 per month</strong>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0} md={1}/>
        </Grid>
        <Button disabled={selected === null} onClick={() => {checkout(selected)}}>
            Go to checkout
        </Button>
      </div>
    </>
  );
};

export default Checkout;
