import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import '../../style/Help.css'

const help = [
  {
    summary: "How to search",
    details: (
      <div style={{ fontFamily: "Bayon" }}>
        <ol>
          <li><span style={{ fontWeight: "normal" }}>Navigate to <a href="/query" style={{ textDecoration: "none", color: "#007bff" }}>query</a>.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Enter 2+ characters.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Click the search button.</span></li>
        </ol>
      </div>
    ),
  },
  {
    summary: "How to adjust margins",
    details: (
      <div style={{ fontFamily: "Bayon" }}>
        <ol>
          <li><span style={{ fontWeight: "normal" }}>Navigate to <a href="/account" style={{ textDecoration: "none", color: "#007bff" }}>account</a>.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Click "margin settings".</span></li>
          <li><span style={{ fontWeight: "normal" }}>Select your desired margin from the slider menu.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Click the save button so that your desired margin remains across sessions.</span></li>
        </ol>
        <p>If the "enabled margin" checkbox is checked, the margin amount will be reflected on the search page.</p>
      </div>
    ),
  },
  {
    summary: "Manage Subscription",
    details: (
      <div style={{ fontFamily: "Bayon" }}>
        <p><strong>If you wish to cancel an existing plan:</strong></p>
        <ol>
          <li><span style={{ fontWeight: "normal" }}>Navigate to your account page.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Select subscription settings.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Select "manage" on the subscription tier that you wish to change.</span></li>
          <li><span style={{ fontWeight: "normal" }}>You will be taken to our payment processor (Stripe). Here, you may cancel your plan.</span></li>
        </ol>
        <p><strong>If you wish to upgrade or downgrade an existing plan:</strong></p>
        <ol>
          <li><span style={{ fontWeight: "normal" }}>Navigate to your account page.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Select subscription settings.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Select "manage" on the subscription tier that you wish to change.</span></li>
          <li><span style={{ fontWeight: "normal" }}>You will be taken to our payment processor (Stripe). Here, you may cancel your plan.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Select "Subscribe" on the plan that you'd like to subscribe to.</span></li>
          <li><span style={{ fontWeight: "normal" }}>Follow checkout steps on Stripe.</span></li>
        </ol>
      </div>
    ),
  },
  {
    summary: "Contact Us",
    details: (
      <div style={{ fontFamily: "Bayon" }}>
        <p>If you need more help, please don't hesitate to send us a text at <a href="tel:+17207455177" style={{ textDecoration: "none", color: "#007bff" }}> (720)-745-5177</a>.</p>
      </div>
    ),
  },
];

const RenderAccordion = (items) => (
  <>
    {items.map((item) => (
      <Accordion style={{boxShadow: 'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ paddingLeft: 5, fontFamily: "Bayon" }}
        >
          {item.summary}
        </AccordionSummary>
        <AccordionDetails>
          <Typography >{item.details}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </>
);

const Help = () => {
  return (
    <>
      <Grid container sx={{ mt: 5, minHeight: 'calc(100vh - 276px)'}}>
        <Grid item xs={1} lg={2} />
        <Grid item xs={10} lg={8}>
          <div align="left" style={{ fontSize: 30, marginBottom: 50, fontFamily: "Bayon"}}>
            FAQs:
          </div>
          {RenderAccordion(help)}
          <Divider />
        </Grid>
        <Grid item xs={1} lg={2} />
      </Grid>
      {/* <Button>
        Contact Us
      </Button> */}
    </>
  );
};

export default Help;
