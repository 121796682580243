import React from "react";
import "../../style/SuccessCheck.css";
import { Grid, IconButton } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { useNavigate } from "react-router-dom";
import { refreshJWT } from '../../API';
import Cookies from "js-cookie";

const SuccessCheckout = () => {

  const history = useNavigate();

  React.useEffect(() => {
    refreshJWT(Cookies.get('userInfo'))
    .then (res => {
        let token = res.data.user.token;
        Cookies.set("userInfo", token);
      history("/");
      })
      .catch((err) => {
        console.error(err.response);
      });
  }, [history]);


  return (
    <>
      <div align="center" style={{ marginTop: 150 }}>
        <Grid container>
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={3}>
            <svg
              style={{ width: 100 }}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                className="path circle"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="path check"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <p className="success">Good to Go!</p>
          </Grid>

          <Grid item xs={4} md={3}>
            <IconButton
              onClick={() => {
                history("/query");
              }}
            >
              <HomeIcon style={{ fontSize: 100 }} />
            </IconButton>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </div>
    </>
  );
};

export default SuccessCheckout;
