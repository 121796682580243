import config from '../../../config/master'

const production = {
    basic_monthly: "price_1L87gQCNqZxm8RYJGIAcjNCx",
    premium_monthly: "price_1L9zaVCNqZxm8RYJCfMkA4Tb",
    basic_annually: "price_1L9zalCNqZxm8RYJ3GuhSqZ8",
    premium_annually: "price_1L9zb1CNqZxm8RYJq4mkKJx4",
    trial: "price_1L87gQCNqZxm8RYJGIAcjNCx"
}

const test = {
    basic_monthly: "price_1KnpqbCNqZxm8RYJBNUsFZVQ",
    premium_monthly: "price_1KnppoCNqZxm8RYJ8IEfrrr9",
    basic_annually: "price_1L72ZuCNqZxm8RYJKMOvMK1H",
    premium_annually: "price_1L72aCCNqZxm8RYJXrbZa268",
    trial: "price_1Lykf5CNqZxm8RYJ7StshWFg"
}

export default (config.isLive ? production : test);
