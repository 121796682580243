import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateOtp } from '../API';
import Cookies from 'js-cookie';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';

const ValidateOtp = () => {
    const history = useNavigate();
    const params = new URLSearchParams(useLocation().search);
    const email = params.get('email');
    const [otp, setOtp] = useState(params.get('otp'));
    const [error, setError] = useState('');
    const [isMobile, setIsMobile] = useState(null);

    useEffect(() => {
        setIsMobile(window.innerWidth < 600);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    const fireValidate = () => {
        console.log(otp);
        console.log(email);
        if (!email || !otp) {
            return;
        }

        validateOtp(email, otp)
            .then((res) => {
                const token = res.data.finalUser.token;
                Cookies.set('userInfo', token);
                history('/account?change-pw=true');
            })
            .catch((err) => {
                setError(err.response.data);
                console.error(err);
            });
    };

    return (
        <>
            <Nav />
            <div
                align="center"
                style={{
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Bayon',
                    lineHeight: 1.15,
                    height: 'calc(100vh - 326px)',
                    paddingTop: isMobile ? 80 : 150,
                    fontSize: isMobile ? 30 : 50,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <TextField
                    style={{ width: isMobile ? '80%' : '50%' }}
                    label="Enter OTP"
                    onChange={handleOtpChange}
                />
                <br />
                <Button
                    onClick={fireValidate}
                    style={{
                        borderColor: '#632c93',
                        textTransform: 'none',
                        color: 'white',
                        backgroundColor: '#632c93',
                        fontFamily: 'Bayon',
                        marginTop: 20,
                        width: isMobile ? '60%' : '20%',
                        height: 50,
                    }}
                    variant="contained"
                >
                    Validate
                </Button>
                {error && (
                    <p style={{ color: 'red', marginTop: 20 }}>{`Error: ${error}`}</p>
                )}
            </div>
            <Footer isMobile={isMobile} />
        </>
    );
};

export default ValidateOtp;