import React from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import Drawer from "../Components/Portal/DrawerRight";
import Nav from "../Components/Portal/Nav";
import { getAccountInfo, setMargin } from '../API';
import Footer from '../Components/Footer';
import Cookies from 'js-cookie'

const Portal = () => {

  const history = useNavigate();

  const [navOpen, setNavOpen] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(null);
  const loggedIn = Cookies.get('userInfo');

  const saveMargin = (newMargin, newMarginActivated) => {
    let holder = { ...userInfo }
    holder.margin = newMargin;
    holder.margin_activated = newMarginActivated;
    setUserInfo(holder);
    setMargin(newMargin, newMarginActivated)
      .then(res => {
        console.log(res.data)
      })
      .catch(err => {
        console.error(err);
      })
  }

  const toggleOpen = () => {
    setNavOpen(!navOpen);
  };

  React.useEffect(() => {
    getAccountInfo()
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // token has expired. remove cookie and direct to login
          Cookies.remove("userInfo");
          history("/home?expired=true");
        }
        console.log(err);
      });
  }, [history])

  if (!loggedIn) {
    return <Navigate to={'/home'} replace />;
  }
  else {
    return (
      <>
        <Nav openNav={toggleOpen} />
        <Drawer open={navOpen} toggleDrawer={toggleOpen} />
        <Outlet context={[userInfo, saveMargin]} />
        <Footer />
      </>
    )
  }
}

export default Portal
