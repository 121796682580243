import React from "react";
import { Dialog, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import { resetPassword } from '../API';
import { useNavigate } from "react-router-dom";

const ForgotPassword = (props) => {
  const [success, setSuccess] = React.useState(false);
  const [email, setEmail] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState('');

  const history = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const directToOtpEntry = () => {
    history(redirectUrl);
  }

  const fireSubmit = () => {
    // email exists and is in correct format
    // TODO: check that email is in correct format
    if (email !== undefined) {
      setLoading(true);
      resetPassword(email)
        .then(res => {
          setLoading(false);
          setSuccess(true);
          console.log(res.data);
          setRedirectUrl(`/validate-otp?email=${res.data.email}`);
        })
        .catch(err => {
          setLoading(false);
          if (err.response.status === 409) {
            setRedirectUrl(`/validate-otp?email=${err.response.data.email}`)
            setSuccess(true);
          }
          else {
            alert(err);
          }
        })
    }
    else {
      alert('error with email')
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.open} onClose={props.handleClose}>
      {!success && (
        <>
          <DialogContent align="center" >
            <TextField style={{ width: "80%" }} label="enter email address" value={email} onChange={handleEmailChange} />
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={fireSubmit}>Enter</Button>
          </DialogActions>
        </>
      )}
      {success && (
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            <p>
              Please check your email for a 6-digit OTP
            </p>
            <p style={{ color: '#999', fontSize: '15px', marginBottom: '20px' }}>
              If you don't see it in your main inbox, please check your spam folder
            </p>
          </div>
          <DialogActions>
            <Button onClick={directToOtpEntry} variant="contained" style={{
              color: "white",
              backgroundColor: "#632c93",
              fontFamily: "Bayon",
            }}>
              Enter OTP
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ForgotPassword;
