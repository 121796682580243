import React from "react";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../style/PortalHome.css";
import { getMetalPrices } from "../../API";
import { Card, CardContent } from "@mui/material";
import { AttachMoney } from "@mui/icons-material";
import { numberWithCommas } from '../../Helpers/functions'
import Carousel from "react-multi-carousel";

const photo_store = "https://catking-pics.s3.us-east-2.amazonaws.com";

const cats = [
  { name: "RENTAL TRUCK", value: "$$$", image: "original/rental_truck.jpeg" },
  { name: "XL24", value: "$$$", image: "original/xl24.jpeg" },
  { name: "SUBARU KIDNEY", value: "$$$", image: "original/subaru_kidney.jpeg" },
  { name: "OVAL", value: "$$$", image: "original/oval.jpeg" },
  { name: "CNV01", value: "$$$", image: "original/cnv01.JPG" },
  { name: "K2BW", value: "$$$", image: "original/k2bw.jpeg" },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Home = () => {
  const history = useNavigate();

  const [metals, setMetals] = React.useState(undefined);

  const [isMobile, setIsMobile] = React.useState(null);

  window.addEventListener("resize", (event) => {
    setIsMobile(window.innerWidth < 600);
  });

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 600);
    getMetalPrices()
      .then((res) => {
        setMetals(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="container">
        {/* <Grid container className="papered">
          <Grid item xs={12}>
            <div style={{fontSize: ".6em", padding: 30}}>Welcome to your portal for real-time catalytic converter values </div>
            </Grid>
          </Grid> */}
        <Grid style={{ padding: "5vw" }} container>
          <Grid item xs={1} sm={2} />
          <Grid item xs={10} sm={8}>
            <div
              align="center"
              style={{
                color: "white",
                fontWeight: "bold",
                fontFamily: "Bayon",
                lineHeight: 1.15,
                marginBottom: isMobile ? 60 : "20vh",
                paddingTop: isMobile ? 80 : 150,
                fontSize: isMobile ? 30 : 50,
              }}
            >
              Your first stop for catalytic converter price consulting{" "}
            </div>
            <Grid item xs={1} sm={2} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: isMobile ? 100 : 0 }}>
            <Button
              variant="contained"
              onClick={() => {
                history("/query");
              }}
              style={{
                marginTop: 7,
                fontFamily: "Bayon",
                width: "60%",
                color: "#632c93",
                height: 65,
                fontSize: 18,
                backgroundColor: "white",
                textTransform: "none",
                fontWeight: "bolder",
                marginLeft: "20%",
              }}
            >
              Search Code
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              onClick={() => {
                history("/generic");
              }}
              style={{
                marginTop: 7,
                width: "70%",
                color: "white",
                fontFamily: "Bayon",
                height: 65,
                fontSize: 18,
                backgroundColor: "#632c93",
                textTransform: "none",
                fontWeight: "bolder",
                marginLeft: "15%",
              }}
            >
              Generics
            </Button>
          </Grid> */}
          <Grid item xs={0} md={1} />
        </Grid>
      </div>
      <Grid
        container
        style={{ marginTop: 30, width: "80%", marginLeft: "10%" }}
        align="center"
        // justifyContent="center"
        rowSpacing={1}
        columnSpacing={1}
      >
        {/* The right/top padding is only to counteract the column spacing to center item */}
        <Grid item xs={12} sm={6} lg={4} style={{ paddingRight: 8, paddingBottom: 8 }}>
          <Card style={{ height: "100%", backgroundColor: "#F9FAFC" }}>
            <CardContent align="center" style={{ display: "flex" }}>
              <AttachMoney
                style={{
                  fontSize: 40,
                  color: "#632c93",
                  borderRadius: 40,
                  padding: 12,
                  backgroundColor: "#F8EFFF",
                }}
              />
              <div className="catcard">
                Platinum (Pt): {metals && numberWithCommas(Math.trunc(metals.platinum))}
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} style={{ paddingRight: 8, paddingBottom: 8 }}>
          <Card style={{ height: "100%", backgroundColor: "#F9FAFC" }}>
            <CardContent align="center" style={{ display: "flex" }}>
              <AttachMoney
                style={{
                  fontSize: 40,
                  color: "#632c93",
                  borderRadius: 40,
                  padding: 12,
                  backgroundColor: "#F8EFFF",
                }}
              />
              <div className="catcard">
                Palladium (Pd): {metals && numberWithCommas(Math.trunc(metals.palladium))}
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} style={{ paddingRight: 8, paddingBottom: 8 }}>
          <Card style={{ height: "100%", backgroundColor: "#F9FAFC" }}>
            <CardContent align="center" style={{ display: "flex" }}>
              <AttachMoney
                style={{
                  fontSize: 40,
                  color: "#632c93",
                  borderRadius: 40,
                  padding: 12,
                  backgroundColor: "#F8EFFF",
                }}
              />
              <div className="catcard">
                Rhodium (Rh): {metals && numberWithCommas(Math.trunc(metals.rhodium))}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div align="center" style={{ paddingTop: 40, paddingBottom: 80 }}>
        <div style={{ fontFamily: "Bayon", fontSize: 30, padding: 40 }}>
          Most popular converter searches
        </div>
        <Grid container style={{ marginTop: 30 }} justifyContent="center">
          <Grid item xs={10}>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              arrows={false}
              renderDotsOutside={false}
              showDots={true}
              swipeable={true}
              draggable={false}
              itemClass="image_item"
              partialVisbile={false}
            >
              {cats.map((cat) => (
                <div
                  align="center"
                  key={cat["Serial Number"]}
                  style={{ border: "none" }}
                >
                  <img
                    title="cat"
                    src={`${photo_store}/${cat["image"]}`}
                    style={{
                      maxHeight: 200,
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="cat"
                  />
                  <div className="catMargin">
                    <strong style={{ fontSize: 18 }}>{cat["name"]}</strong>
                    {
                      <div style={{ marginTop: 10, fontSize: 16 }}>
                        <div style={{ color: "grey", display: "inline" }}>
                          Value:{" "}
                        </div>
                        <strong style={{ color: "black" }}>$$$</strong>
                      </div>
                    }
                  </div>
                </div>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;
