import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import React from 'react';

const Contact = () => {

    const [isMobile, setIsMobile] = React.useState(null);

    React.useEffect(() => {
        setIsMobile(window.innerWidth < 600);
    }, []);

    window.addEventListener("resize", (event) => {
        setIsMobile(window.innerWidth < 600);
    });

    return (
        <>
            <Nav />
            <div
                align="center"
                style={{
                    color: "black",
                    fontWeight: "bold",
                    fontFamily: "Bayon",
                    lineHeight: 1.15,
                    height: 'calc(100vh - 326px)',
                    paddingTop: isMobile ? 80 : 150,
                    fontSize: isMobile ? 30 : 50,
                }}>
                Having issues?
                <br />
                <br />
                <a href="tel:+17207455177" style={{ textDecoration: "none", color: "#007bff" }}> (720)-745-5177 </a>
                for 24/7 support
            </div>
            <Footer isMobile={isMobile} />
        </>
    )
}

export default Contact;