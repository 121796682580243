import { AppBar, Button, Toolbar } from "@mui/material";
import "../style/Nav.css";
import Login from "./Login";
import Register from "../Pages/Register.js";
import React from "react";
import logo_23 from "../images/logo_23.png";
import { useNavigate } from "react-router-dom";
import ForgotPassword from '../Pages/ForgotPassword';

const Nav = (props) => {
  const history = useNavigate();
  const [loginOpen, setLoginOpen] = React.useState(props.open || false);
  const [registerOpen, setRegisterOpen] = React.useState(false);
  const [forgotPWOpen, setForgotPWOpen] = React.useState(false);

  const toggleLoginDialog = () => {
    setLoginOpen(!loginOpen);
  };

  const toggleRegisterDialog = () => {
    setRegisterOpen(!registerOpen);
  };

  const loadRegister = () => {
    toggleLoginDialog();
    toggleRegisterDialog();
  };

  const loadForgotPW = () => {
    toggleLoginDialog();
    setForgotPWOpen(!forgotPWOpen);
  }

  return (
    <AppBar className="nav_bar" color="inherit">
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <img
            alt="cat_logo"
            src={logo_23}
            height={40}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history("/");
            }}
          />
        </div>
        <Button
          onClick={toggleLoginDialog}
          style={{
            borderColor: "#632c93",
            textTransform: "none",
            color: "#632c93",
            fontFamily: "Bayon"
          }}
          variant="outlined"
        >
          Log in
        </Button>
        <Button
        onClick={toggleRegisterDialog}
        style={{
          textTransform: "none",
          color: "white",
          backgroundColor: "#632c93",
          fontFamily: "Bayon",
          marginLeft: 12
        }}
        variant="contained"
        >
          sign up
        </Button>
      </Toolbar>
      <Login
        open={loginOpen}
        handleClose={toggleLoginDialog}
        loadRegister={loadRegister}
        loadForgotPW={loadForgotPW}
        expired={props.open}
      />
      <Register open={registerOpen} handleClose={toggleRegisterDialog} />
      <ForgotPassword open={forgotPWOpen} handleClose={loadForgotPW} />
    </AppBar>
  );
};

export default Nav;
