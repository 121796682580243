import React from "react";
import "../style/Footer.css";
import { Grid } from "@material-ui/core";
import { Facebook, LinkedIn, Instagram } from '@mui/icons-material';
import disclaimer from '../images/DISCLAIMER.pdf';
import { useNavigate } from "react-router-dom";

const Footer = (props) => {

  const history = useNavigate();

  return (
    <div className="footerContainer">
      <span className="centeredFooter">
        <Grid container align="center">
          <Grid container xs={12} sm={3} className="desktop">
            <Grid item xs={12} >
              ©CatKing. All Rights Reserved
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6}>
            <Grid item xs={12} sm={3}>
              <div style={{ cursor: 'pointer' }} onClick={() => { history('/contact') }}>
                Contact
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              Privacy Policy
            </Grid>
            <Grid item xs={12} sm={3}>
            <a href={disclaimer} style={{textDecoration: "none", color: "white"}} target="_blank">
            Terms & Conditions
            </a>
            </Grid>
            <Grid item xs={12} sm={3}>
              Cookie Policy
            </Grid>
          </Grid>
          <Grid container xs={12} sm={3} className="desktop">
              <Grid item xs={12}>
                    <span style={{verticalAlign: 'top'}}>
                    <a href="https://instagram.com/catkingapp" target="_blank" style={{textDecoration: "none", color: "white"}}><Facebook style={{marginLeft: 8, marginRight: 8}} /></a>
                    <a href="https://instagram.com/catkingapp" target="_blank" style={{textDecoration: "none", color: "white"}}><LinkedIn style={{marginLeft: 8, marginRight: 8}} /></a>
                        <a href="https://instagram.com/catkingapp" target="_blank" style={{textDecoration: "none", color: "white"}}><Instagram style={{marginLeft: 8, marginRight: 8}} /></a>
                    </span>
              </Grid>
          </Grid>
          <Grid container xs={12} sm={3} className="mobile">
            <Grid item xs={12} style={{paddingTop: 22}}>
              ©CatKing. All Rights Reserved
            </Grid>

            <Grid item xs={12} style={{marginTop: 12}}>
                    <span>
                        <Facebook style={{margin: 8}}/>
                        <LinkedIn style={{margin: 8}}/>
                        <Instagram style={{margin: 8}}/>
                    </span>
              </Grid>
          </Grid>
        </Grid>
      </span>
    </div>
  );
};

export default Footer;
