import React from "react";
import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Typography,
  Drawer,
  IconButton,
  Box
} from "@mui/material";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import DeblurIcon from "@mui/icons-material/Deblur";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined";
import HelpCenterIcon from "@mui/icons-material/HelpCenterOutlined";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import "../../style/Drawer.css";
import CloseIcon from "@mui/icons-material/Close";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  justifyContent: "flex-end",
}));

const StyledDrawer = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'max(240px, 50vw)'
  },
  anchor: 'right',
  role: 'presentation'
}));

const DrawerRight = (props) => {
  const history = useNavigate();

  const switchPages = (page) => {
    history(page);
    props.toggleDrawer();
  };

  const list = (anchor) => (
    <StyledDrawer>
      <List style={{ paddingTop: 0 }}>
        <ListItem
          onClick={() => {
            switchPages("/");
          }}
          disableGutters
          disablePadding
        >
          <ListItemButton style={{ paddingTop: 0 }}>
            <ListItemIcon>
              <HomeIcon style={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "black" }}>
              <Typography style={{ fontFamily: "Bayon" }}>Home </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            switchPages("/account");
          }}
          disableGutters
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <AccountCircleIcon style={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "black" }}>
              <Typography style={{ fontFamily: "Bayon" }}>Account </Typography>
            </ListItemText>{" "}
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            switchPages("/query");
          }}
          disableGutters
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <SearchOutlined style={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "black" }}>
              <Typography style={{ fontFamily: "Bayon" }}>Search </Typography>
            </ListItemText>{" "}
          </ListItemButton>
        </ListItem>
        {/* <ListItem onClick={() => {history('/addcat')}} style={{cursor: 'pointer'}}>
          <ListItemIcon>
            <ListItemIcon><AddCircleOutlineIcon/></ListItemIcon>
            <ListItemText style={{color: 'black'}}>Add Cat</ListItemText>
          </ListItemIcon>
        </ListItem> */}
        <ListItem
          onClick={() => {
            switchPages("/generic");
          }}
          disableGutters
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <DeblurIcon style={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "black" }}>
              <Typography style={{ fontFamily: "Bayon" }}>Generics </Typography>
            </ListItemText>{" "}
          </ListItemButton>
        </ListItem>
      <Divider />
      <ListItem
          onClick={() => {
            switchPages("/help");
          }}
          disableGutters
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <HelpCenterIcon style={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "black" }}>
              <Typography style={{ fontFamily: "Bayon" }}>Help </Typography>
            </ListItemText>{" "}
          </ListItemButton>
        </ListItem>
      <ListItem
        onClick={() => {
          Cookies.remove("userInfo");
          switchPages("/");
        }}
        disableGutters
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <ExitToAppIcon style={{ color: "black" }} />
          </ListItemIcon>
          <ListItemText style={{ color: "black" }}>
            <Typography style={{ fontFamily: "Bayon" }}>Logout </Typography>
          </ListItemText>{" "}
        </ListItemButton>
      </ListItem>

      </List>
    </StyledDrawer>
  );

  return (
    <>
      <Drawer
        open={props.open}
        onClose={props.toggleDrawer}
        anchor="right"
        transitionDuration={650}
      >
        <DrawerHeader>
          <IconButton onClick={props.toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        {list("right")}
      </Drawer>
    </>
  );
};

export default DrawerRight;
